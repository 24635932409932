import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Monthlytable from "./monthly-table"; // Ensure this path is correct
import { useLocation } from "react-router-dom";
import AdvertisementComponent from "../utilities/advertismentComponent";

const monthFullName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function FooterDrop() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selecYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [gameOptions, setGameOptions] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [dropValue, getDropValue] = useState([]);

  const location = useLocation();
  const isPrivacy = location.pathname.includes("/privacy-policy");
  const isDisclaimer = location.pathname.includes("/disclaimer");
  const isAbout = location.pathname.includes("/about-us");

  const currentMonth = moment().format("MMMM");
  const currentYear = new Date().getFullYear();

  const monthArray = monthFullName.map((month, index) => ({
    value: index + 1,
    label: month,
  }));
  const yearArray = Array.from({ length: 10 }, (_, i) => ({
    value: currentYear - i,
    label: currentYear - i,
  }));

  useEffect(() => {
    async function fetchGameNames() {
      try {
        const response = await fetch(
          "https://api.sattakingvip.co.in/getGameName",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({}),
          }
        );
        const result = await response.json();
        setGameOptions(result);
      } catch (error) {
        console.error(error);
      }
    }
    fetchGameNames();
  }, []);

  const handleSelectChange = (setter) => (selected) => {
    setter(selected);
  };

  const apiData = useCallback((params) => {
    fetch("https://api.sattakingvip.co.in/getmonthdata", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        month: params.month,
        year: params.year,
        gameName: params.gameName || "",
        result: "",
        days: params.days,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setSelectedData(json);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to fetch data", { className: "toast-message" });
      });
  }, []);

  const getAllGameDetails = () => {
    if (selectedMonth && selecYear && selectedOption) {
      const selectedMonthValue = selectedMonth.value;
      const selectedYearValue = selecYear.value;

      let allData = {
        month: selectedMonthValue,
        year: selectedYearValue,
        gameName: selectedOption.value,
        result: "",
        days: moment(
          `${selectedYearValue}-${selectedMonthValue}`,
          "YYYY-MM"
        ).daysInMonth(),
      };

      if (
        selectedMonthValue > moment().month() + 1 &&
        selectedYearValue === currentYear
      ) {
        toast.error("Selected month out of Date", {
          className: "toast-message",
        });
      } else {
        apiData(allData);
        getDropValue(allData);
      }
    } else {
      toast.error("All Fields Required", { className: "toast-message" });
    }
  };

  const customStyles = {
    control: (provided) => ({ ...provided, minHeight: "46px", height: "46px" }),
    input: (provided) => ({ ...provided, minHeight: "46px" }),
    valueContainer: (provided) => ({
      ...provided,
      height: "46px",
      padding: "0 8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "46px",
      display: "flex",
      alignItems: "center",
    }),
    indicatorsContainer: (provided) => ({ ...provided, height: "46px" }),
  };

  return (
    <div className="footer">
       {/* <AdvertisementComponent type='even' /> */}
      {!(isAbout || isDisclaimer || isPrivacy) && (
        <>
          <Monthlytable gameData={selectedData} dropValue={dropValue} />
          <div className="yellow-container pt-5">
            <div className="bottom-container">
              <div className="selection-container">
                <div className="col-12">
                  <div className="row">
                    <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                      <Select
                        menuPlacement="auto"
                        value={selectedMonth}
                        onChange={handleSelectChange(setSelectedMonth)}
                        options={monthArray}
                        placeholder="Select Month"
                        styles={customStyles}
                      />
                    </div>
                    <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                      <Select
                        menuPlacement="auto"
                        value={selecYear}
                        onChange={handleSelectChange(setSelectedYear)}
                        options={yearArray}
                        placeholder="Select Year"
                        styles={customStyles}
                      />
                    </div>
                    <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                      <Select
                        menuPlacement="auto"
                        value={selectedOption}
                        onChange={handleSelectChange(setSelectedOption)}
                        options={gameOptions}
                        placeholder="Select Game"
                        styles={customStyles}
                      />
                    </div>
                    <div className="col-l-3 col-md-3 col-sm-12 col-xs-12 pb-2">
                      <button
                        className="form-control"
                        id="go-button"
                        onClick={getAllGameDetails}
                      >
                        Go
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <AdvertisementComponent type='random' /> */}
        </>
      )}
      <ToastContainer
        autoClose={2000}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        pauseOnHover
        draggable
      />
    </div>
  );
}

export default FooterDrop;
